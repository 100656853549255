import React, { useState } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"

import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"
import useInterval from "../../../utils/useInterval"

// Import common components
import { StandardContainer, StyledHeading, StyledTextLink } from "../../common"
import { StyledSingleImage } from "../imageBlocks/singleImage"

// Styles
const StyledFeatureBanner = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4rem;

  > ${StandardContainer} {
    margin: 8rem 0 3rem 0;
    z-index: 10;
  }
  nav {
    display: flex;
    margin-top: 2rem;
  }
  ${StyledTextLink} {
    margin-right: 1rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    height: 100vh;
    flex-direction: row;
    align-items: flex-end;
    background: #000;
    > ${StandardContainer} {
      margin: 0;
    }
  }
`
const FeatureBannerBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
`

const AwardImage = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  img {
    max-width: 94px;
    width: 100%;
    position: absolute;
    bottom: 3%;
    right: 3%;
  }

  @media ${props => props.theme.breakpoints.medium} {
    img {
      bottom: -7%;
    }
  }

  @media ${props => props.theme.breakpoints.large} {
    img {
      position: initial;
    }
  }
`

const FeatureBannerBackgroundVideo = styled(ReactPlayer)`
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 0;
  video {
    position: absolute;
    width: 100%;
    height: 100% !important;
    object-fit: cover;
  }
  @media ${props => props.theme.breakpoints.medium} {
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      display: block;
      z-index: 1;
    }
  }
`
const FeatureBackgroundWrapper = styled(motion.div)`
  z-index: 0;
  width: 100%;
  height: 300px;
  display: block;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  @media ${props => props.theme.breakpoints.medium} {
    position: absolute;
    height: 100%;
  }
`

const FeatureBannerBackground = ({ _type, videoURL, asset }) => {
  return _type === "bannerImage" && asset ? (
    <FeatureBannerBackgroundImage fluid={asset.fluid} />
  ) : _type === "bannerVideo" && videoURL ? (
    <FeatureBannerBackgroundVideo
      url={videoURL}
      playing
      playsinline
      width="100%"
      height="auto"
      loop={true}
      muted
    />
  ) : null
}

export const FeatureBanner = ({ backgrounds, heading, links, image }) => {
  const [bgIndex, setBgIndex] = useState(0)
  const wrappedIndex = wrap(0, backgrounds.length, bgIndex)

  useInterval(
    () => {
      setBgIndex(bgIndex + 1)
    },
    backgrounds.length > 1 ? 10000 : null
  )
  return (
    <StyledFeatureBanner>
      <StandardContainer
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <StyledHeading
          pullColor
          size="h1"
          measureNarrow
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.8,
            type: "spring",
            damping: 80,
          }}
        >
          {heading}
        </StyledHeading>
        {image && image.asset && (
          <AwardImage>
            <img
              src={image.asset.fluid.src}
              alt="registered master builders award 2020"
            />
          </AwardImage>
        )}
        {links && (
          <motion.nav
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 1.2,
              type: "spring",
              damping: 80,
            }}
          >
            {links.map(({ linkText, pageReference }) => (
              <StyledTextLink
                size="L"
                pullColor
                to={`/${pageReference && pageReference.slug.current}`}
              >
                {linkText}
              </StyledTextLink>
            ))}
          </motion.nav>
        )}
      </StandardContainer>
      {backgrounds.length > 1 ? (
        <AnimatePresence>
          <FeatureBackgroundWrapper
            key={wrappedIndex}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              transition: {
                type: "spring",
                damping: 1000,
              },
            }}
          >
            <FeatureBannerBackground {...backgrounds[wrappedIndex]} />
          </FeatureBackgroundWrapper>
        </AnimatePresence>
      ) : (
        backgrounds.length && (
          <FeatureBackgroundWrapper key={wrappedIndex}>
            <FeatureBannerBackground {...backgrounds[wrappedIndex]} />
          </FeatureBackgroundWrapper>
        )
      )}
    </StyledFeatureBanner>
  )
}
