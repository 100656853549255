import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

// Import Commmon Components
import { SanityPortableText, StandardContainer } from "../../common"

// Styles
const StyledServiceScroller = styled(StandardContainer)`
  > nav {
    display: none;
  }

  @media ${props => props.theme.breakpoints.large} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 5rem;
    > nav {
      display: block;
      grid-column: 1 / span 3;
      grid-row: 1;
      ul {
        position: sticky;
        top: 40px;
        display: flex;
        flex-direction: column;
      }
      a {
        font-size: ${props => props.theme.typography.sizes.h4};
        opacity: 0.5;
        transition: all 0.2s ease;
        margin-bottom: 1rem;
        cursor: pointer;
        &.active {
          color: ${props => props.theme.colors.text.pullColor};
          opacity: 1;
        }
      }
    }
    > div {
      grid-column: 3 / span 10;
      grid-row: 1;
    }
  }
`

const StyledServiceSection = styled.section`
  padding-bottom: 3rem;
  > div:nth-child(2) {
    margin: 2rem auto;
  }
  @media ${props => props.theme.breakpoints.large} {
    padding-bottom: 6rem;
    > div:nth-child(2) {
      max-width: 1140px;
      margin: 3rem auto;
      padding-left: ${props => props.theme.padding.hDesk};
      padding-right: ${props => props.theme.padding.hDesk};
    }
  }
`

const ServiceSection = ({ _key, text, image }) => {
  return (
    <StyledServiceSection id={_key}>
      {image && <Img fluid={image.asset.fluid} />}
      {text && <SanityPortableText text={text} />}
    </StyledServiceSection>
  )
}

export const ServiceScroller = ({ sections }) => {
  return (
    sections.length && (
      <StyledServiceScroller>
        <nav>
          <ul>
            {sections.map(item => (
              <ScrollLink
                to={item._key}
                smooth={true}
                spy={true}
                activeClass="active"
              >
                {item.title}
              </ScrollLink>
            ))}
          </ul>
        </nav>
        <div>
          {sections.map(item => (
            <ServiceSection {...item} />
          ))}
        </div>
      </StyledServiceScroller>
    )
  )
}
