import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import is from "styled-is"
import { motion, AnimatePresence } from "framer-motion"

const StyledContainer = styled(motion.div)`
    max-width: 990px;
    width: 100%;
    margin: auto;
    padding: 0 1.4rem;
`

const StyledHeading = styled.h2`
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    color: #F0534C;

    @media ${props => props.theme.breakpoints.medium} {
        font-size: 45px;
        line-height: 55px;
    }
`

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${props => props.theme.breakpoints.medium} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${props => props.theme.breakpoints.large} {
        grid-template-columns: repeat(3, 1fr);
        gap: 150px;
    }
`

const StyledCol = styled.div`
    max-width: 230px;
    width: 100%;
    text-align: center;
    color: #5F5F5F;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    jusitfy-content: center;

    @media ${props => props.theme.breakpoints.medium} {
        margin: 50px auto 0 auto;
    }

    @media ${props => props.theme.breakpoints.large} {
        margin: 100px 0 0 0;
    }

    p{
        font-size: 18px !important;
        line-height: 25px !important;
    }

    img{
        max-width: 105px;
        width: 100%;
        margin: 20px 0;
    }
`

export const Awards = ({ heading, items }) => {
    return (
        <StyledContainer initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
                delay: 0.8,
                type: "spring",
                damping: 80,
            }}>
            <StyledHeading>{heading}</StyledHeading>
            <StyledGrid>
                {items.map((col, i) => (
                    <StyledCol key={i}>
                        <img src={col.image.asset.fluid.src} alt="builstrong award" />
                        <p>{col.text}</p>
                    </StyledCol>
                ))}
            </StyledGrid>
        </StyledContainer>
    )
}
