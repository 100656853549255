import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Import common components
import {
  StandardContainer,
  SanityPortableText,
  StyledHeading,
  ScrollReveal,
} from "../../common"
import { getSanityPath } from "../../../utils/sanity"

// Styles
const StyledIntroGrid = styled(StandardContainer)`
  display: grid;
  grid-row-gap: 2rem;

  @media ${props => props.theme.breakpoints.medium} {
    --intro-grid-spacer: ${props => props.theme.padding.hDesk};
    padding-right: 0;
    padding-left: 0;
    grid-template-columns:
      var(--intro-grid-spacer) repeat(12, 1fr)
      1fr var(--intro-grid-spacer);
    grid-row-gap: var(--intro-grid-spacer);

    > div:nth-child(1) {
      grid-column: 8 / span 6;
      grid-row: 1 / span 2;
    }
    > div:nth-child(2) {
      grid-column: 2 / span 4;
      grid-row: 2 / span 2;
    }
    > div:nth-child(3) {
      grid-column: 9 / span 7;
      grid-row: 3 / span 2;
    }
    > div:nth-child(4) {
      grid-column: 3 / span 6;
      grid-row: 5;
    }
  }
`

const StyledProjectGridCard = styled(ScrollReveal)`
  article {
    padding: 1rem 0;
    h3 {
      color: ${props => props.theme.colors.text.pullColor};
    }
  }
  a {
    position: relative;
    display: block;
  }
  @media ${props => props.theme.breakpoints.large} {
    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      h3 {
        color: #fff;
        z-index: 1;
        transform: translateY(40px);
        opacity: 0;
        transition: all 0.5s 0s ease;
      }

      &:before {
        content: "";
        background: ${props => props.theme.colors.text.pullColor};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        z-index: 0;
        opacity: 0;
        transition: all 0.5s 0.4s ease;
      }
    }
    &:hover {
      article {
        h3 {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.4s;
        }
        &:before {
          opacity: 0.8;
          transition-delay: 0s;
        }
      }
    }
  }
`

const StyledIntroMoreLink = styled(ScrollReveal)`
  background: ${props => props.theme.colors.text.pullColor};
  height: 0;
  padding-bottom: 100%;
  color: #fff;
  width: 100%;
  position: relative;
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    font-size: ${props => props.theme.typography.sizes.h4};
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-column: 10 / span 5;
    margin-top: var(--intro-grid-spacer);
  }
`

const ProjectGridCard = ({ image, projectReference }) => {
  const path = projectReference ? getSanityPath(projectReference) : null
  const { projectName } = projectReference || {}

  return (
    <StyledProjectGridCard>
      <Link to={path}>
        {image && <Img fluid={image.asset.fluid} />}
        <article>
          <StyledHeading as="h3" size="h3">
            {projectName}
          </StyledHeading>
        </article>
      </Link>
    </StyledProjectGridCard>
  )
}

export const IntroGrid = ({ text, projects }) => {
  return (
    <StyledIntroGrid>
      <ScrollReveal className="grid-text">
        {text && <SanityPortableText text={text} />}
      </ScrollReveal>
      {projects.map(item => (
        <ProjectGridCard {...item} />
      ))}

      <StyledIntroMoreLink>
        <Link to="/projects">View more projects</Link>
      </StyledIntroMoreLink>
    </StyledIntroGrid>
  )
}
