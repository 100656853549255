import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import is from "styled-is"

import { motion, AnimatePresence } from "framer-motion"

// Import Common Components
import { StandardContainer, StyledTextLink } from "../../common"
import { getSanityPath } from "../../../utils/sanity"

// Styles
const StyledRelatedProjects = styled(StandardContainer)`
  padding-top: ${props => props.theme.padding.vDesk};
  padding-bottom: ${props => props.theme.padding.vDesk};
  max-width: 1440px;
  ${StyledTextLink} {
    display: flex;
    margin-top: 2rem;
  }
  > article {
    div {
      display: none;
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    > article {
      display: grid;
      grid-template-columns: 1.6fr 1fr;
      grid-gap: 4rem;
      div {
        display: block;
      }
      nav {
        align-self: center;
      }
    }
  }
`
const StyledRelatedProject = styled(Link)`
  font-size: ${props => props.theme.typography.sizes.h2};
  display: block;
  padding: 0.8rem 0;
  opacity: 0.5;
  transition: 0.4s ease;
  ${is("isActive")`
    color: ${props => props.theme.colors.text.pullColor};
    opacity: 1;
  `}
`

const StyledRelatedImage = styled(Img)`
  width: 100%;
  height: 0px;
  padding-bottom: 120%;
`

const variants = {
  active: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
  closed: {
    y: 10,
    opacity: 0,
    transition: {
      type: "spring",
      damping: 300,
    },
  },
}

const RelatedImage = ({ node, ...props }) => {
  const { relatedImage } = node || {}
  return (
    <motion.div {...props}>
      {relatedImage && <StyledRelatedImage fluid={relatedImage.asset.fluid} />}
    </motion.div>
  )
}

export const RelatedProjects = ({ projects }) => {
  const [activeKey, setActiveKey] = useState(0)

  console.log('random', projects)

  return (
    <StyledRelatedProjects wrappedStandard>
      <article>
        <nav>
          {projects.map(({ node }, index) => {
            const path = node ? getSanityPath(node) : null
            const { projectName } = node || {}
            return (
              <StyledRelatedProject
                to={path}
                onMouseOver={() => setActiveKey(index)}
                onFocus={() => setActiveKey(index)}
                isActive={index === activeKey}
              >
                {projectName}
              </StyledRelatedProject>
            )
          })}
          <StyledTextLink to="/projects" pullColor size="L">
            View all projects
          </StyledTextLink>
        </nav>
        <div>
          <AnimatePresence exitBeforeEnter>
            <RelatedImage
              key={activeKey}
              {...projects[activeKey]}
              variants={variants}
              initial="closed"
              animate="active"
              exit="closed"
            />
          </AnimatePresence>
        </div>
      </article>
    </StyledRelatedProjects>
  )
}
