import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

// Styles
const StyledImageGrid = styled.div`
  display: grid;
  grid-row-gap: 1.5rem;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};

  @media ${props => props.theme.breakpoints.medium} {
    --image-grid-spacer: ${props => props.theme.padding.hDesk};
    padding-left: 0;
    padding-right: 0;
    grid-row-gap: 0;
    grid-template-columns:
      var(--image-grid-spacer) 1fr var(--image-grid-spacer)
      1fr var(--image-grid-spacer);
    grid-template-rows: var(--image-grid-spacer) auto var(--image-grid-spacer) auto;
    article:nth-child(1) {
      grid-column: 2 / span 1;
      grid-row: 1 / span 2;
    }
    article:nth-child(2) {
      grid-column: 4 / span 2;
      grid-row: 2 / span 3;
    }
    article:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: 4 / span 2;
    }
  }
  @media ${props => props.theme.breakpoints.large} {
    grid-template-rows: var(--image-grid-spacer) auto 2rem auto;
    article:nth-child(1) {
      padding-right: var(--image-grid-spacer);
    }
    article:nth-child(2) {
      padding-right: 4rem;
    }
    article:nth-child(3) {
      grid-column: 2 / span 1;
      padding-left: var(--image-grid-spacer);
      align-self: flex-start;
    }
  }
`

export const ImageGrid = ({ imageOne, imageTwo, imageThree }) => {
  return (
    <StyledImageGrid>
      {imageOne && (
        <article>
          <Img fluid={imageOne.asset.fluid} />
        </article>
      )}
      {imageTwo && (
        <article>
          <Img fluid={imageTwo.asset.fluid} />
        </article>
      )}
      {imageThree && (
        <article>
          <Img fluid={imageThree.asset.fluid} />
        </article>
      )}
    </StyledImageGrid>
  )
}
