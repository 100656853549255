import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { match } from "styled-is"

// Import common components
import { StyledHeading } from "../../common"
import { getSanityPath } from "../../../utils/sanity"

// Styles
const StyledProjectGrid = styled.div`
  --project-grid-spacer: ${props => props.theme.padding.hDesk};
  display: grid;
  padding-left: ${props => props.theme.padding.hMobile};
  padding-right: ${props => props.theme.padding.hMobile};
  grid-row-gap: 6rem;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: var(--project-grid-spacer) 100px 1fr 0.4fr 0.5fr 0.8fr 1fr 100px var(
        --project-grid-spacer
      );
    grid-row-gap: 7rem;
    padding-left: 0;
    padding-right: 0;
  }
`
const StyledProjectGridCard = styled.div`
  article {
    margin-top: 1rem;
    * {
      margin: 0;
    }
  }
  section {
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: ${props => props.theme.colors.text.pullColor};
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 1;
      opacity: 0;
      transition: all 0.5s 0.8s ease;
    }
    > ${StyledHeading} {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      position: absolute;
      z-index: 2;
      transform: translateY(40px);
      opacity: 0;
      transition: all 0.5s 0s ease;
    }
  }
  > a:hover {
    section {
      > ${StyledHeading} {
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.8s;
      }
      &:before {
        opacity: 0.8;
        transition-delay: 0s;
      }
    }
  }
  @media ${props => props.theme.breakpoints.medium} {
    grid-column-start: ${props => props.gridStart || 1};
    grid-column-end: ${props => props.gridEnd || 2};
    align-self: ${props => props.gridAlign || "flex-start"};

    ${match("gridAlign", "flex-end")`
      margin-top: var(--project-grid-spacer); 
    `}
    ${match("gridAlign", "flex-start")`
      margin-bottom: var(--project-grid-spacer); 
    `}
    ${match("gridStart", "1")`
      article{
        padding-left:var(--project-grid-spacer);
      }
    `}
  }
`
const ProjectGridCard = ({
  image,
  gridStart,
  gridEnd,
  gridAlign,
  projectReference,
}) => {
  const { projectName, projectType } = projectReference || {}
  const path = projectReference ? getSanityPath(projectReference) : null
  return (
    image && (
      <StyledProjectGridCard
        gridStart={gridStart}
        gridEnd={gridEnd}
        gridAlign={gridAlign}
      >
        <Link to={path}>
          <section>
            <StyledHeading as="h3">View Project</StyledHeading>
            {image.asset && <Img fluid={image.asset.fluid} />}
          </section>
          <article>
            <StyledHeading as="h3" size="h3" pullColor>
              {projectName}
            </StyledHeading>
            <StyledHeading as="h4" size="h4" pullColor>
              {projectType}
            </StyledHeading>
          </article>
        </Link>
      </StyledProjectGridCard>
    )
  )
}

export const ProjectGrid = ({ projects }) => {
  return (
    <StyledProjectGrid>
      {projects.map(item => (
        <ProjectGridCard {...item} />
      ))}
    </StyledProjectGrid>
  )
}
