import React from "react"
import styled from "styled-components"

// Common components
import { StandardGridContainer, SanityPortableText } from "../../common"

// Styles
const StyledContactDetails = styled(StandardGridContainer)`
  grid-row-gap: 0.8rem;

  > * {
    color: ${props => props.theme.colors.text.pullColor};
    grid-column: 2 / span 12;
  }
  @media ${props => props.theme.breakpoints.large} {
    > * {
      grid-column: span 4;
    }
    &:before {
      content: "";
      grid-column: 1 / span 2;
    }
  }
`

export const ContactDetails = ({ details }) => {
  return (
    <StyledContactDetails wrappedStandard>
      {details.map(item => (
        <SanityPortableText text={item} />
      ))}
    </StyledContactDetails>
  )
}
