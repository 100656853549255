import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
// Import common components
import {
  StandardCarousel,
  StyledHeading,
  StandardContainer,
} from "../../common"

// Styles
const StyledBioCard = styled.div`
  width: 50vw;
  flex: 0 0 auto;
  color: ${props => props.theme.colors.text.pullColor};
  padding-right: 1rem;
  box-sizing: content-box;
  > h4 {
    margin-top: 0.8rem;
    line-height: 1.2;
  }
  @media ${props => props.theme.breakpoints.medium} {
    width: 400px;
    padding-right: 3rem;
  }
`
const StyledBioCarousel = styled(StandardContainer)`
  cursor: grab;
  header {
    padding-bottom: 3rem;
  }
`

export const BioCarousel = ({ bios, heading }) => {
  return (
    bios && (
      <StyledBioCarousel>
        <header>
          <StyledHeading as="h2" size="h2" pullColor>
            {heading}
          </StyledHeading>
        </header>
        <StandardCarousel>
          {bios.map(
            ({ image, name, position }) =>
              image && (
                <StyledBioCard>
                  <Img fluid={image.asset.fluid} />
                  <StyledHeading as="h4" size="h4" pullColor>
                    {name}
                  </StyledHeading>
                  <p>{position}</p>
                </StyledBioCard>
              )
          )}
        </StandardCarousel>
      </StyledBioCarousel>
    )
  )
}
