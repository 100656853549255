import React from "react"
import PropTypes from "prop-types"

// Import Blocks
import {
  FeatureBanner,
  TestimonialsSlider,
  PageSpacer,
  StandardText,
  ImageText,
  ImageCarousel,
  ServiceScroller,
  SingleImage,
  ContactDetails,
  EditorialLayout,
  ImageGrid,
  IntroGrid,
  ProjectGrid,
  RelatedProjects,
  BioCarousel,
  Awards,
  ProjectCarousel,
} from "./"

export const PageBlocks = ({ blocks }) => {
  const block = blocks.map(block => {
    switch (block._type) {
      case "featureBanner":
        return <FeatureBanner key={block._key} {...block} />
      case "testimonials":
        return <TestimonialsSlider key={block._key} {...block} />
      case "pageSpacer":
        return <PageSpacer key={block._key} {...block} />
      case "standardText":
        return <StandardText key={block._key} {...block} />
      case "imageText":
        return <ImageText key={block._key} {...block} />
      case "imageCarousel":
        return <ImageCarousel key={block._key} {...block} />
      case "projectCarousel":
        return <ProjectCarousel key={block._key} {...block} />
      case "serviceScroller":
        return <ServiceScroller key={block._key} {...block} />
      case "singleImage":
        return <SingleImage key={block._key} {...block} />
      case "contactDetails":
        return <ContactDetails key={block._key} {...block} />
      case "editorialLayout":
        return <EditorialLayout key={block._key} {...block} />
      case "imageGrid":
        return <ImageGrid key={block._key} {...block} />
      case "introGrid":
        return <IntroGrid key={block._key} {...block} />
      case "projectGrid":
        return <ProjectGrid key={block._key} {...block} />
      case "bioCarousel":
        return <BioCarousel key={block._key} {...block} />
      case "awards":
        return <Awards key={block._key} {...block} />
      default:
        return null
    }
  })
  return <>{block}</>
}

PageBlocks.propTypes = {
  blocks: PropTypes.array.isRequired,
}
