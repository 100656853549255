import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import is from "styled-is"

// Import Common components
import { SanityPortableText, StandardContainer } from "../../common"

// Styles
const StyledImageText = styled(StandardContainer)`
  display: grid;
  grid-row-gap: 2rem;
  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(12, 1fr);
    > div {
      grid-row: 1;
      align-self: center;
    }
    > div:nth-child(1) {
      grid-column: 8 / span 5;
    }
    > div:nth-child(2) {
      grid-column: 1 / span 6;
    }
    ${is("reversed")`
      > div:nth-child(2) {
        grid-column: 8 / span 5;
      }
      > div:nth-child(1) {
        grid-column: 1 / span 6;
      }
    `}
  }
`

export const ImageText = ({ image, text, reverse }) => {
  return (
    <StyledImageText wrappedStandard reversed={reverse}>
      <div>{image && <Img fluid={image.asset.fluid} />}</div>
      {text && (
        <div>
          <SanityPortableText text={text} />
        </div>
      )}
    </StyledImageText>
  )
}
