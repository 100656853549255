import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

// Import common components
import { StandardContainer, StyledHeading } from "../../common"

// Styles
const StyledProjectBanner = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > ${StandardContainer} {
    margin: 8rem 0 3rem 0;
    position: relative;
    z-index: 5;
  }
  nav {
    display: flex;
    margin-top: 2rem;
  }
  @media ${props => props.theme.breakpoints.medium} {
    height: 100vh;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 4rem;
    > ${StandardContainer} {
      margin: 0;
    }
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 0;
      background: rgba(0, 0, 0, 0.6);
    }
  }
`
const ProjectBannerBackground = styled(BackgroundImage)`
  width: 100%;
  height: 300px;
  @media ${props => props.theme.breakpoints.medium} {
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const ProjectBanner = ({ banner, projectName, projectType }) => {
  return (
    <StyledProjectBanner>
      <StandardContainer>
        <StyledHeading as="h1" size="h1" measure pullColor>
          {projectName}
        </StyledHeading>
        <StyledHeading as="h2" size="h3" measure pullColor>
          {projectType}
        </StyledHeading>
      </StandardContainer>
      {banner && <ProjectBannerBackground fluid={banner.asset.fluid} />}
    </StyledProjectBanner>
  )
}
