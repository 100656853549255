import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

// Common Components
import { StandardContainer, SanityPortableText } from "../../common"
// Styles
const StyledEditorialLayout = styled(StandardContainer)`
  display: grid;
  grid-row-gap: 1.5rem;

  @media ${props => props.theme.breakpoints.medium} {
    --editorial-grid-spacer: ${props => props.theme.padding.hDesk};
    padding-left: 0;
    padding-right: 0;
    grid-row-gap: var(--editorial-grid-spacer);
    grid-template-columns:
      var(--editorial-grid-spacer) repeat(12, 1fr)
      1fr var(--editorial-grid-spacer);
    grid-template-rows: var(--editorial-grid-spacer) auto var(
        --editorial-grid-spacer
      ) auto;
    .img-one {
      grid-column: 2 / span 5;
      grid-row: 2 / span 3;
    }
    .img-two {
      grid-column: 9 / span 7;
      grid-row: 3 / span 2;
    }
    .block-text {
      grid-column: 8 / span 6;
      grid-row: 1 / span 2;
    }
  }
`

export const EditorialLayout = ({ text, imageOne, imageTwo, reverse }) => {
  return (
    <StyledEditorialLayout>
      {imageOne && (
        <div className="img-one">
          <Img fluid={imageOne.asset.fluid} />
        </div>
      )}
      {imageTwo && (
        <div className="img-two">
          <Img fluid={imageTwo.asset.fluid} />
        </div>
      )}
      {text && (
        <div className="block-text">
          <SanityPortableText text={text} />
        </div>
      )}
    </StyledEditorialLayout>
  )
}
