import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { match } from "styled-is"

// Common Components
import { StandardGridContainer } from "../../common"
// Styles
const StyledSingleImage = styled(StandardGridContainer)`
  > div {
    grid-column: 2 / span 12;
  }
  @media ${props => props.theme.breakpoints.large} {
    --image-grid-spacer: ${props => props.theme.padding.hDesk};
    ${match("align", "Left")`
      > div{ 
        grid-column: 1 / span 12;
      }
    `}
    ${match("align", "Right")`
      > div{ 
        grid-column: 3 / span 12;
      }
    `}
  }
`
export const SingleImage = ({ image, align }) => {
  return (
    <StyledSingleImage align={align}>
      {image && <Img fluid={image.asset.fluid} />}
    </StyledSingleImage>
  )
}
