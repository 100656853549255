import React, { useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"

//Import common components
import { StyledHeading, ScrollReveal } from "../../common"

// Styles
const StyledTestimonialSlider = styled(ScrollReveal)`
  padding-top: ${props => props.theme.padding.vMobile};
  padding-bottom: ${props => props.theme.padding.vBottom};

  > nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    padding-top: ${props => props.theme.padding.vDesk};
    padding-bottom: ${props => props.theme.padding.vDesk};
  }
`
const SliderPager = styled.button`
  width: 80px;
  height: 0.25rem;
  background: ${props => props.theme.colors.text.pullColor};
  margin: 0 0.5rem;
  cursor: pointer;
  transition: opacity 0.2s;
  opacity: ${props => (props.isActive ? "1" : "0.5")};
`

const Testimonial = ({ quote, author, ...props }) => {
  return (
    <motion.div {...props}>
      <StyledHeading as="h3" size="h3" pullColor centerText measureWide>
        {quote}
      </StyledHeading>
      <StyledHeading size="h4" as="h4" pullColor centerText>
        {author}
      </StyledHeading>
    </motion.div>
  )
}

export const TestimonialsSlider = ({ quotes }) => {
  const [pageIndex, setPageIndex] = useState(0)
  const wrappedIndex = wrap(0, quotes.length, pageIndex)
  return (
    <StyledTestimonialSlider wrappedStandard container>
      {quotes.length ? (
        <>
          <AnimatePresence exitBeforeEnter>
            <Testimonial
              key={wrappedIndex}
              {...quotes[wrappedIndex]}
              initial={{
                opacity: 0,
                y: 10,
              }}
              animate={{
                opacity: 1,
                y: 0,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{
                transition: {
                  type: "spring",
                  damping: 600,
                },
              }}
              drag="x"
              dragMomentum={false}
              dragElastic={false}
              dragConstraints={{ left: 0, right: 0 }}
              onDragEnd={(e, { offset, velocity }) => {
                if (offset.x > 100) {
                  setPageIndex(pageIndex - 1)
                } else if (offset.x < -100) {
                  setPageIndex(pageIndex + 1)
                }
              }}
            />
          </AnimatePresence>
          {quotes.length > 1 && (
            <nav>
              {quotes.map((item, index) => (
                <SliderPager
                  onClick={() => setPageIndex(index)}
                  isActive={wrappedIndex === index}
                />
              ))}
            </nav>
          )}
        </>
      ) : (
        <StyledHeading size="h2" pullColor centerText measureWide>
          Add some testimonials
        </StyledHeading>
      )}
    </StyledTestimonialSlider>
  )
}
