import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

// Import common components
import { StandardCarousel } from "../../common"
// Import SVG
import ArrowLeft from "../../../svg/arrow-left.svg"
import ArrowRight from "../../../svg/arrow-right.svg"

// Styles
const StyledImageCarouselItem = styled(Img)`
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
  user-select: none;
  box-sizing: content-box;
  position: relative;
  opacity: 0.5;
  transition: opacity 0.5s ease;
  &.is-selected {
    opacity: 1;
  }
`
const StyledImageCarousel = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`

const StyledContainer = styled.div`
position: relative;
> button {
  width: 70px;
  position: absolute;
  bottom: -60px;
  z-index: +10;
  &.emb-next{
    right: 0;
  }
  &.emb-prev{
    right: calc(${props => props.theme.padding.hMobile} * 3)
  }
  @media ${props => props.theme.breakpoints.medium} {
    &.emb-next {
      right: calc(${props => props.theme.padding.hMobile} * 3);
    }
    &.emb-prev {
      right: calc(${props => props.theme.padding.hMobile} * 6);
    }
  }
}
`

const StyledCounter = styled.div`
  position: absolute;
  bottom: -60px;
  left: calc(${props => props.theme.padding.hMobile});
  z-index: +10;

  @media ${props => props.theme.breakpoints.medium} {
    left: calc(${props => props.theme.padding.hMobile} * 3);
  }

  p{
    font-size: 35px;
    color: #F0534C;
    line-height: 1;
  }
`

const ImageCarouselItem = ({ asset }) => {

  return asset && <StyledImageCarouselItem fluid={asset.fluid} />
}

export const ImageCarousel = ({ images }) => {
  const [embla, setEmbla] = useState(null)
  const [prev, setPrev] = useState(false)
  useEffect(() => {
    if (embla) {
      embla.on("select", () => {
        if (embla.canScrollPrev()) {
          setPrev(true)
        } else {
          setPrev(false)
        }
      })
    }
  }, [embla])

  const [currentSlide, setCurrentSlide] = useState(1)

  return (
    <StyledContainer>
      <StyledImageCarousel>
        <StandardCarousel padHorizontal emblaRef={setEmbla}>
          {images.map(item => (
            <ImageCarouselItem {...item} />
          ))}
        </StandardCarousel>
      </StyledImageCarousel>
      <StyledCounter>
        <p>{currentSlide} of {images.length}</p>
      </StyledCounter>

      {prev && (
        <button onClick={() => { embla.scrollPrev(); setCurrentSlide(prev => prev - 1) }} className="emb-prev">
          <ArrowLeft />
        </button>
      )}
      <button onClick={() => { embla.scrollNext(); setCurrentSlide(prev => currentSlide < images.length ? prev + 1 : prev) }} className="emb-next">
        <ArrowRight />
      </button>
    </StyledContainer>
  )
}
