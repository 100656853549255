import React from "react"
import styled from "styled-components"
// Import Common Components
import { SanityPortableText, StyledTextLink, ScrollReveal } from "../../common"

// Styles
const StyledStandardText = styled(ScrollReveal)`
  nav {
    display: flex;
    margin: 1rem 0;
  }
  ${StyledTextLink} {
    margin-right: 1rem;
  }
`

export const StandardText = ({
  text,
  wrapped,
  measured,
  headingMeasure,
  links,
}) => {
  return (
    <StyledStandardText wrappedNarrow={wrapped} container>
      {text && (
        <SanityPortableText
          text={text}
          measured={measured}
          headingMeasure={headingMeasure}
        />
      )}
      {links && (
        <nav>
          {links.map(({ linkText, pageReference }) => (
            <StyledTextLink
              pullColor
              to={`/${pageReference && pageReference.slug.current}`}
            >
              {linkText}
            </StyledTextLink>
          ))}
        </nav>
      )}
    </StyledStandardText>
  )
}
